@use 'theme.scss' as *;

.navbar {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;

  position: sticky;
  top: 5px;
  z-index: 1;

  background-color: var(--navbar-background-color);

  padding-left: 15px;
  padding-right: 15px;

  font-family: 'Montserrat', sans-serif;

  /* make it as wide and tall as the content */
  width: fit-content;

  border-radius: 4px;

  border: 1px solid var(--navbar-border-color);

  &.transition {
    transition: background-color 0.5s;
    transition-timing-function: ease-out;
  }
}

.navbarItem {
  background-color: var(--navbar-background-color);

  padding: 15px;
  cursor: pointer;

  /* text styling */
  color: var(--text-color);
  text-decoration: none;

  /* no highlight */
  user-select: none;

  &.transition {
    transition: background-color 0.5s;
    transition-timing-function: ease-out;
  }
}

.navbarItem:hover {
  background-color: var(--navbar-item-hover-color);

  box-shadow: inset 0 -3px 0 var(--navbar-item-underline-color);

  &.transition {
    transition:
      background-color 0.5s,
      box-shadow 0.2s;
    transition-timing-function: ease-out;
  }
}

.navbarActive {
  background-color: var(--navbar-item-click-color);
  box-shadow: inset 0 -3px 0 var(--navbar-item-underline-color);

  &.transition {
    transition:
      background-color 0.5s,
      box-shadow 0.2s;
    transition-timing-function: ease-out;
  }
}
