@use 'theme.scss' as *;

.lectures {
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
}

.subheading {
  margin-bottom: 0;
}

.section {
  border-left: 0.2rem solid var(--color-gray);
  padding-left: 1.5rem;
}

.strong {
  color: var(--color-lightred);
}

.link + .link {
  display: block;
  margin-top: 0.8rem;
}
