@use './theme.scss' as *;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Open+Sans:wght@500&display=swap');

:root {
  font-synthesis: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  --animate-duration: 400ms;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);

  &.transition {
    transition: background-color 0.5s;
    transition-timing-function: ease-out;
  }
}

html,
body {
  margin: 0;
}

.content {
  margin: 30px auto auto;
  width: 70%;

  padding-bottom: 3rem;
}

html {
  font-size: 100%;
}

h1 {
  font-size: 4.21rem;
  margin-top: 15px;
}

h2 {
  font-size: 3.158rem;
}

h3 {
  font-size: 2.369rem;
}

h4 {
  font-size: 1.777rem;
}

h5 {
  font-size: 1.333rem;
}

small {
  font-size: 0.75rem;
}

/* unvisited link */
.link:link {
  text-decoration: none;
  font-weight: bold;

  transition: color 0.15s;
  transition-timing-function: ease-out;

  color: var(--link-color);
}
.link:visited {
  text-decoration: none;
  font-weight: bold;

  transition: color 0.15s;
  transition-timing-function: ease-out;

  color: var(--link-color);
}
.link:hover {
  text-decoration: underline;
  font-weight: bold;

  transition: color 0.15s;
  transition-timing-function: ease-out;

  color: var(--link-hover-color);
}
.link:active {
  text-decoration: underline;
  font-weight: bold;

  transition: color 0.15s;
  transition-timing-function: ease-out;

  color: var(--link-hover-color);
}
